import React from 'react';
import { Box, Flex, Text, Heading, Image, Link } from '@chakra-ui/react';
import blueLogoWithBg from '../assets/images/blueLogoWithBg.svg';
import theme from '../theme';
import NavMenu from '../components/header/NavMenu';

const Footer: React.FC = () => {
  return (
    <Box bg="#F8F8F8" p="60px" px="100px" textAlign="center">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        align="center"
      >
        <Box
          textAlign="right"
          mb={{ base: 8, md: 0 }}
          w="315px"
          mx={{ base: 'auto', md: 0 }}
        >
          <Image src={blueLogoWithBg} alt="Logo" h="35.43px" mb={8} />
          <Heading
            fontFamily={theme.fonts.family.medium}
            fontWeight="500"
            fontSize={`clamp(20px, 1.5vw, 22px)`}
            lineHeight="33.73px"
            color="black"
            mb={8}
          >
            درباره اپلای‌طوری
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            lineHeight="28px"
            color="black"
            textAlign="justify"
          >
            اپلای‌طوری با بهره‌گیری از هوش مصنوعی پیشرفته، فرآیند انتخاب دانشگاه
            و استاد مناسب برای ادامه تحصیل در خارج از کشور را ساده‌تر و کارآمدتر
            می‌کند. ما متعهد به ارائه بهترین فرصت‌های تحصیلی و حمایت از مسیر
            موفقیت شما هستیم.
          </Text>
        </Box>

        <Box
          textAlign="right"
          mb={{ base: 8, md: 0 }}
          w="315px"
          mx={{ base: 'auto', md: 0 }}
        >
          <Heading
            fontFamily={theme.fonts.family.medium}
            fontWeight="500"
            fontSize={`clamp(20px, 1.5vw, 22px)`}
            lineHeight="33.73px"
            color="black"
            mb={8}
          >
            ارتباط با ما
          </Heading>
          <Text
            mb={2}
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            lineHeight="28px"
            color="black"
            textAlign="justify"
          >
            <strong>ایمیل:</strong> info@ApplyTory.com
          </Text>
          <Text
            mb={2}
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            lineHeight="28px"
            color="black"
            textAlign="justify"
          >
            <strong>پشتیبانی:</strong> support@ApplyTory.com
          </Text>
          <Link href="https://t.me/applytory_admin">
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              lineHeight="28px"
              color="black"
              textAlign="justify"
            >
              <strong> با ما در تماس باشید:</strong> پشتیبانی تلگرام
            </Text>
          </Link>
        </Box>

        <Box textAlign="right" w="315px" mx={{ base: 'auto', md: 0 }}>
          <Heading
            fontFamily={theme.fonts.family.medium}
            fontWeight="500"
            fontSize={`clamp(20px, 1.5vw, 22px)`}
            lineHeight="33.73px"
            color="black"
            mb={8}
          >
            اپلای طوری
          </Heading>
          <NavMenu title="خانه" TextColor="black" />
          <NavMenu title="سوالات متداول" TextColor="black" />
          <NavMenu title="درباره ما" TextColor="black" />
          <NavMenu title="تماس با ما" TextColor="black" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
