import React from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Accordion,
  Image,
  Link,
} from '@chakra-ui/react';
import MoreContentIcon from '../assets/icons/MoreContentIcon.svg';
import Devider2 from '../assets/images/Devider2.svg';
import CustomAccordion from '../components/faq/Accordion';
import theme from '../theme';
import faqContent from '../contents/faqContent.json';

const Faq: React.FC = () => {
  const sections = faqContent.sections;

  return (
    <Box
      mx={{ base: '20px', md: '60px', lg: '120px', xl: '180px' }}
      mt={{ base: '24px', md: '48px', lg: '68px' }}
    >
      <Heading
        fontFamily={theme.fonts.family.medium}
        fontWeight="500"
        fontSize={{
          base: theme.fonts.size.medium,
          md: `clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`,
        }}
        mb={5}
        textAlign="right"
        bg="#3080E233"
        p={{ base: '20px', md: '30px', lg: '40px' }}
        borderRadius="16px"
      >
        سوالات متداول
      </Heading>
      <Accordion allowToggle allowMultiple>
        {sections.map((section, index) => (
          <CustomAccordion
            key={index}
            id={index}
            title={section.title}
            content={section.content}
            icon={MoreContentIcon}
          />
        ))}
      </Accordion>
      <Image
        src={Devider2}
        width="100%"
        my={{ base: '44px', md: '68px', lg: '88px' }}
      />
      <Heading
        fontFamily={theme.fonts.family.bold}
        fontWeight="700"
        fontSize={{
          base: theme.fonts.size.large,
          md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
        }}
        mb="48px"
        textAlign="center"
      >
        روش‌های ارتباط با ما
      </Heading>
      {/* <Flex
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: '34px', md: '48px', lg: '68px' }}
      > */}
      <Box
        // ml={{ base: '0', md: '30px', lg: '60px', xl: '110px' }}
        textAlign="center"
        justifyContent="center"
        height="100%"
        maxWidth="500px"
        mx="auto"
      >
        {/* <Box mb={{ base: '24px', md: '40px', lg: '70px' }}>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="600"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
              mb="6px"
            >
              آدرس:
            </Text>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={{
                base: theme.fonts.size.lil,
                md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
              }}
            >
              بزرگراه سرلشکر بابایی، بلوار نیروی زمینی ارتش، مجتمع الماس، مرکز
              نوآوری فردا، طبقه اول
            </Text>
          </Box> */}
        <Box alignSelf="center">
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            mb="24px"
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: theme.fonts.size.small,
              md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
            }}
            color="#0C0B0B"
          >
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="600"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
            >
              ارتباط سازمانی:
            </Text>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="500"
              fontSize={{
                base: theme.fonts.size.lil,
                md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
              }}
            >
              info@ApplyTory.com
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            mb="24px"
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: theme.fonts.size.small,
              md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
            }}
            color="#0C0B0B"
          >
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="600"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
            >
              پشتیبانی:
            </Text>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="500"
              fontSize={{
                base: theme.fonts.size.lil,
                md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
              }}
            >
              support@ApplyTory.com
            </Text>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            mb="24px"
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: theme.fonts.size.small,
              md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
            }}
            color="#0C0B0B"
          >
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="600"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
            >
              با ما در تماس باشید:
            </Text>
            <Link href="https://t.me/applytory_admin">
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="500"
                fontSize={{
                  base: theme.fonts.size.lil,
                  md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                }}
              >
                پشتیبانی تلگرام
              </Text>
            </Link>
          </Flex>
        </Box>
      </Box>
      {/* <Box textAlign="center" width="100%" mt={{ base: '24px', md: '0' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.836753056975!2d51.34760161519899!3d35.76826188016856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e015d2c7ab1f9%3A0xfb9e0f6615468613!2sTehran!5e0!3m2!1sen!2sir!4v1638812418283!5m2!1sen!2sir"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            title="location"
          ></iframe>
        </Box> */}
      {/* </Flex> */}
    </Box>
  );
};

export default Faq;
