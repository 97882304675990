import React, { useContext } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { AppContext } from 'src/context/AppContext';
import HeaderContainerRight from './HeaderContainerRight';
import theme from 'src/theme';
import searchICON from 'src/assets/icons/search-normal.png';
import cpuICON from 'src/assets/icons/cpu.png';
import shieldICON from 'src/assets/icons/shield-tick.png';

const HeaderContent: React.FC = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    return null;
  }

  const { headerContent } = appContext;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      padding="20px"
    >
      {/* Header Section */}
      <Box
        width="100%"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Box
          width={{ base: '100%', md: '100%' }}
          // paddingLeft={{ base: '16px', md: '53px' }}
          textAlign={{ base: 'center', md: 'center' }}
          mb={{ base: '40px', md: '0' }}
        >
          <HeaderContainerRight
            title1={headerContent.title1}
            title2={headerContent.title2}
            description={headerContent.description}
            learnMore={headerContent.learnMore}
          />
        </Box>
        {/* <Box
          width={{ base: '100%', md: '30%' }}
          display="flex"
          justifyContent={{ base: 'center', md: 'left' }}
        > */}
        {/* Placeholder for Search Box */}
        {/* </Box> */}
      </Box>

      {/* New Boxes Section */}
      <Box
        width="100%"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
        mt="10px"
        padding="10px"
        borderRadius="10px"
      >
        {[
          {
            title: 'جستجوی هوشمندانه',
            description:
              'با اپلای‌طوری، انتخاب دانشگاه و استاد هیچ‌وقت این‌قدر ساده نبوده است! لیست‌های شخصی‌سازی‌شده بر اساس علاقه‌ها و نیازهای شما، فرآیند جستجو را سریع‌تر و دقیق‌تر می‌کند.',
          },
          {
            title: 'تکنولوژی پیشرفته هوش مصنوعی',
            description:
              'اولین سرویس ایرانی مبتنی بر هوش مصنوعی برای اپلای. با استفاده از الگوریتم‌های پیشرفته، بهترین گزینه‌ها را مطابق با مسیر تحصیلی و حرفه‌ای شما پیشنهاد می‌دهیم.',
          },
          {
            title: 'انتخاب با اطمینان',
            description:
              'اطلاعات همیشه به‌روز و دقیق در دستان شما! با اپلای‌طوری، تصمیم‌گیری برای آینده تحصیلی‌تان با اطمینان و سرعت بیشتر انجام می‌شود.',
          },
        ].map((box, index) => (
          <Box
            key={index}
            width={{ base: '100%', md: '30%' }}
            minHeight="200px"
            padding="20px"
            background="rgba(255, 255, 255, 0.1)" // Glass effect
            borderRadius="12px"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            boxShadow="0 4px 15px rgba(0, 0, 0, 0.2)"
            backdropFilter="blur(10px)" // Glassmorphism
          >
            {/* Add Icon */}
            <Image
              src={
                index === 0 ? searchICON : index === 1 ? cpuICON : shieldICON
              }
              alt="Icon"
              boxSize="40px"
              mb="10px"
            />
            <Text
              fontFamily={theme.fonts.family.body}
              fontSize={{
                base: 'theme.fonts.size.medium',
                md: 'theme.fonts.size.big',
              }}
              color={theme.colors.texts.white}
              fontWeight="bold"
            >
              {box.title}
            </Text>
            <Text
              mt="10px"
              fontFamily={theme.fonts.family.body}
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.white}
            >
              {box.description}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HeaderContent;
