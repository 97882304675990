import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import Vector from '../../assets/icons/Vector.svg';
import NetworkingConnections from '../../assets/icons/NetworkingConnections.png';
import Hourglass from '../../assets/icons/Hourglass.png';
import AIICON from '../../assets/icons/AI.png';
import Circuit from '../../assets/icons/circuit.svg';
import Gear from '../../assets/icons/Gear.svg';

const AboutUsContainer: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      w="100%"
      maxW={{ base: 'unset', md: '1169px' }}
      mx="auto"
      justify="center"
      align="center"
      mb={{ base: '40px', md: '88px' }}
      flexDirection={{ base: 'column', md: 'initial' }}
    >
      <Box w="100%" textAlign="center">
        <Heading
          fontFamily={theme.fonts.family.bold}
          fontWeight="700"
          fontSize={{
            base: '24px',
            md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
          }}
          lineHeight="44px"
          color={theme.colors.texts.dark}
          mb="40px"
        >
          اهمیت یافتن دانشگاه، دانشکده و استاد مناسب
        </Heading>
        <Text
          fontFamily={theme.fonts.family.body}
          fontWeight="400"
          fontSize={{
            base: '16px',
            md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
          }}
          color={theme.colors.texts.dark}
          lineHeight="28px"
          mb={{ base: '20px', md: 8 }}
        >
          انتخاب دانشگاه، دانشکده و استاد مناسب برای ادامه تحصیل در خارج از کشور
          یکی از مهم‌ترین تصمیماتی است که می‌تواند تأثیر بسزایی بر مسیر تحصیلی و
          حرفه‌ای شما داشته باشد. این انتخاب نه تنها بر کیفیت آموزش و پژوهش شما
          تأثیر می‌گذارد، بلکه می‌تواند فرصت‌های شغلی و شبکه‌سازی شما را نیز شکل
          دهد.
        </Text>
        <Flex
          justify="center"
          align={{ base: 'center', md: 'start' }}
          flexDirection={{ base: 'column', md: 'row' }}
          mt={{ base: '40px', md: '80px' }}
          mb={{ base: '20px', md: '53px' }}
          gap={{ base: '20px', md: '32px' }}
        >
          <Box
            flex="1"
            textAlign={{ base: 'center', md: 'right' }}
            mb={{ base: '30px', md: 0 }}
            maxW={{ base: '100%', md: '33%' }}
          >
            <Image
              src={NetworkingConnections}
              h="90px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.dark}
              lineHeight="38px"
              mb="20px"
            >
              چرا پیدا کردن استاد مناسب مساله مهمی است؟
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.dark}
              lineHeight="28px"
              textAlign="justify"
            >
              اساتید نقش کلیدی در هدایت پژوهش‌ها و توسعه علمی دانشجویان دارند.
              انتخاب استاد مناسب می‌تواند به شما کمک کند تا در زمینه‌های
              تحقیقاتی مورد علاقه خود پیشرفت کنید، دسترسی به منابع و شبکه‌های
              علمی گسترده‌تری داشته باشید و فرصت‌های شغلی بهتری را در آینده رقم
              بزنید. همچنین، همکاری با استادانی که دارای تجربه و تخصص بالا در
              حوزه مورد نظر شما هستند، می‌تواند تجربه تحصیلی شما را غنی‌تر و
              موثرتر سازد.
            </Text>
          </Box>
          <Box
            flex="1"
            textAlign={{ base: 'center', md: 'right' }}
            bg={theme.colors.primary}
            color="white"
            p={{ base: '20px', md: '25px 50px 35px 30px' }}
            borderRadius="8px"
            boxShadow="0px 10px 40px 0px #22222226"
            // mx={{ base: 'auto', md: '40px' }}
            mb={{ base: '30px', md: 0 }}
            maxW={{ base: '100%', md: '33%' }}
          >
            <Image
              src={AIICON}
              h="90px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.white}
              lineHeight="38px"
              mb="20px"
            >
              راه‌حل اپلای‌طوری
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.white}
              lineHeight="28px"
              textAlign="justify"
            >
              اپلای‌طوری با بهره‌گیری از هوش مصنوعی پیشرفته و به‌روزرسانی‌های
              مستمر، لیست اساتید و دانشگاه‌های مناسب شما را به صورت دقیق و
              به‌روز ارائه می‌دهد. برخلاف روش‌های سنتی که زمان‌بر و هزینه‌زا
              هستند، اپلای‌طوری با خودکارسازی فرآیند جستجو و شخصی‌سازی نتایج،
              زمان و هزینه شما را به طور قابل توجهی کاهش می‌دهد. به این ترتیب،
              شما می‌توانید با اطمینان بیشتری انتخاب‌های تحصیلی خود را انجام
              دهید و مسیر تحصیلی خود را بهینه کنید.
            </Text>
          </Box>
          <Box
            flex="1"
            textAlign={{ base: 'center', md: 'right' }}
            mb={{ base: '20px', md: 0 }}
            mx={{ base: 'auto', md: '0' }}
            maxW={{ base: '100%', md: '33%' }}
          >
            <Image
              src={Hourglass}
              h="90px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.dark}
              lineHeight="38px"
              mb="20px"
            >
              چرا فرایند پیدا کردن استاد مناسب زمان‌بر است؟
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.dark}
              lineHeight="28px"
              textAlign="justify"
            >
              در حال حاضر، بسیاری از شرکت‌ها و گروه‌های مختلف برای تهیه
              لیست‌هایی شامل 100 تا 200 استاد بر اساس دسته‌بندی‌های کلی مانند
              مهندسی برق، هوش مصنوعی، فیزیک و غیره، مبالغ زیادی دریافت می‌کنند.
              این لیست‌ها اغلب بر اساس معیارهای عمومی تهیه شده‌اند و فاقد
              شخصی‌سازی و دقت لازم برای تطابق با نیازها و اهداف فردی شما هستند.
              علاوه بر این، یکی از معایب اصلی این لیست‌ها، قدیمی بودن اطلاعات
              آن‌ها است؛ معمولاً این لیست‌ها با فاصله‌های زمانی بیش از یک سال
              به‌روزرسانی می‌شوند که باعث می‌شود اطلاعات ارائه شده منسوخ و کمتر
              قابل اعتماد باشند.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AboutUsContainer;
