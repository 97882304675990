import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import image_1 from '../../assets/images/image_content_1.png';
import image_2 from '../../assets/images/image_content_2.png';

const ImageContent: React.FC = () => {
  const imageSize = useBreakpointValue({ base: '300px', md: '480px' });

  return (
    <Box
      sx={{
        overflowX: 'visible',
        position: 'relative',
        zIndex: 10,
      }}
    >
      <Flex
        w="100%"
        maxW="1169px"
        mx="auto"
        justify="center"
        align="flex-start"
        mb={{ base: '40px', md: '88px' }}
        flexDirection={{ base: 'column', md: 'row' }}
        position="relative"
      >
        {/* Image Column */}
        <Box
          h={{ base: '400px', md: '550px' }}
          w={{ base: '90%', md: '50%' }}
          mx={{ base: 'auto', md: '0px' }}
          mb={{ base: '30px', md: '0px' }}
          ml={{ base: '20px', md: '70px' }}
          sx={{
            position: 'sticky',
            top: '20px',
          }}
        >
          <Image
            src={image_2}
            alt="Example"
            objectFit="cover"
            w={imageSize}
            h={imageSize}
            position="absolute"
            top="0"
            left="0"
          />
          <Image
            src={image_1}
            alt="Example"
            objectFit="cover"
            w={imageSize}
            h={imageSize}
            position="absolute"
            bottom="0"
            right="0"
          />
        </Box>
        <Box
          w={{ base: '100%', md: '50%' }}
          textAlign={{ base: 'center', md: 'right' }}
          h="auto"
        >
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            lineHeight="44px"
            color={theme.colors.texts.dark}
            mb="32px"
          >
            جستجوی آسان و سریع
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            textAlign="justify"
            color={theme.colors.texts.dark}
            lineHeight="32px"
            mb="40px"
          >
            برای شروع فرآیند اپلای، کافی است زمینه‌های تحقیقاتی مورد علاقه، کشور
            مقصد و رتبه دانشگاه دلخواه خود را وارد کنید. در کوتاه‌ترین زمان
            ممکن، لیستی شخصی‌سازی‌شده از اساتید و دانشگاه‌های مرتبط به صورت
            آنلاین در اختیار شما قرار خواهد گرفت. این ابزار، جستجوی هوشمند و
            سریع را برای کاربران فراهم می‌کند.
          </Text>
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            lineHeight="44px"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            textAlign="justify"
            color={theme.colors.texts.dark}
            mb="32px"
          >
            مطابقت علایق شما با اساتید متخصص، با هوش مصنوعی پیشرفته
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            textAlign="justify"
            color={theme.colors.texts.dark}
            lineHeight="32px"
            mb="40px"
          >
            اپلای‌طوری با بهره‌گیری از هوش مصنوعی پیشرفته، اساتید متناسب با
            علایق، تجربیات کاری و مهارت‌های شما را شناسایی می‌کند. این تکنولوژی
            نوآورانه، اولین سرویس در نوع خود است که لیست اساتید مبتنی بر هوش
            مصنوعی ارائه می‌دهد و مسیر تحصیلی شما را به سمت موفقیت هدایت می‌کند.
          </Text>
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            lineHeight="44px"
            textAlign="justify"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            color={theme.colors.texts.dark}
            mb="32px"
          >
            صرفه‌جویی قابل توجه در زمان
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            textAlign="justify"
            color={theme.colors.texts.dark}
            lineHeight="32px"
            mb="40px"
          >
            بدون نیاز به جستجوی دستی و مواجهه با اطلاعات پراکنده، شما می‌توانید
            در کمتر از یک ساعت، لیستی دقیق و به‌روز از اساتید و دانشگاه‌ها
            دریافت کنید. با استفاده از اپلای‌طوری، فرآیند جستجو بهینه و خودکار
            می‌شود تا زمان بیشتری برای برنامه‌ریزی تحصیلی خود داشته باشید.
          </Text>
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            lineHeight="44px"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            color={theme.colors.texts.dark}
            mb="32px"
            textAlign="justify"
          >
            به‌روزرسانی‌های لحظه‌ای و دسترسی به جدیدترین اطلاعات
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            color={theme.colors.texts.dark}
            lineHeight="32px"
            textAlign="justify"
          >
            اپلای‌طوری با به‌روزرسانی‌های زمان واقعی، اطمینان می‌دهد که شما
            همیشه به جدیدترین اطلاعات اساتید و دانشگاه‌ها دسترسی دارید. این
            ویژگی تضمین می‌کند که گزینه‌های انتخابی شما همیشه بهترین و دقیق‌ترین
            خواهند بود.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ImageContent;
