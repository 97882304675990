import React, { useState } from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import theme from '../../theme';

const MotionBox = motion(Box);

interface ListItem {
  number?: number;
  title?: string;
  subItems?: string[];
  text?: string;
  description?: string;
}

interface ContentItem {
  type: string;
  text?: string;
  items?: string[] | ListItem[];
}

interface AccordionProps {
  id: number;
  title: string;
  content: ContentItem[];
  icon: string;
}

const CustomAccordion: React.FC<AccordionProps> = ({
  id,
  title,
  content,
  icon,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAccordionChange = (isOpen: boolean) => {
    setIsExpanded(isOpen);
  };

  return (
    <AccordionItem
      borderRadius="lg"
      mb="24px"
      overflow="hidden"
      transition="all 0.3s"
      border="1px solid "
      borderColor={isExpanded ? 'blue.300' : '#CACACA'}
      onChange={() => handleAccordionChange}
    >
      <AccordionButton
        onClick={toggleAccordion}
        _hover={{ bg: 'gray.50' }}
        py={4}
        px={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flex="1" textAlign="right" gap="12px" align="center">
          <Image
            src={icon}
            width="32px"
            transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="transform 0.3s ease-in-out"
          />
          <Text
            fontFamily={theme.fonts.family.medium}
            fontWeight="500"
            fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
            color="#333D55"
          >
            {title}
          </Text>
        </Flex>
      </AccordionButton>
      <AccordionPanel overflow="hidden" pt={0} pr="80px">
        <MotionBox
          initial={{ maxHeight: 0 }}
          animate={{ maxHeight: isExpanded ? '1000px' : '0px' }}
          transition={{ duration: 0.5 }}
        >
          {content.map((item, index) => {
            switch (item.type) {
              case 'paragraph':
                return (
                  <Text key={index} mb={4}>
                    {item.text}
                  </Text>
                );
              case 'bulletList':
                return (
                  <Box key={index} mb={4} as="ul">
                    {(item.items as string[])?.map((bullet, idx) => (
                      <Text key={idx} mb={2} as="li" mr="40px">
                        {bullet}
                      </Text>
                    ))}
                  </Box>
                );
              case 'numberedList':
                return (
                  <Box key={index} mb={4} as="ol">
                    {(item.items as ListItem[])?.map((listItem, idx) => (
                      <Box key={idx} mb={2}>
                        <Text fontWeight="bold" mb={1}>
                          {listItem.number}. {listItem.text}
                        </Text>
                        {listItem.description && (
                          <Text ml={4} color="gray.600">
                            {listItem.description}
                          </Text>
                        )}
                      </Box>
                    ))}
                  </Box>
                );
              case 'nestedNumberedList':
                return (
                  <Box key={index} mb={4}>
                    {(item.items as ListItem[])?.map((listItem, idx) => (
                      <Box key={idx} mb={4}>
                        <Text fontWeight="bold" mb={1}>
                          {listItem.number}. {listItem.title}
                        </Text>
                        {listItem.subItems && (
                          <Box ml={4} mt={2} as="ul">
                            {listItem.subItems.map((subItem, subIdx) => (
                              <Text key={subIdx} mb={1} as="li" mr="40px">
                                {subItem}
                              </Text>
                            ))}
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                );
              case 'note':
                return (
                  <Box
                    key={index}
                    mb={4}
                    p={4}
                    bg="yellow.100"
                    borderRadius="md"
                  >
                    <Text fontWeight="bold" color="yellow.800">
                      {item.text}
                    </Text>
                  </Box>
                );
              case 'highlight':
                return (
                  <Box
                    key={index}
                    mb={4}
                    p={4}
                    bg="green.100"
                    borderRadius="md"
                  >
                    <Text
                      fontWeight="bold"
                      color="green.800"
                      textAlign="justify"
                    >
                      {item.text}
                    </Text>
                  </Box>
                );
              default:
                return null;
            }
          })}
        </MotionBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CustomAccordion;
