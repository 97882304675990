import React from 'react';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import theme from 'src/theme';
import starIcon from '../../assets/icons/star.png';

import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

type HeaderContainerRightProps = {
  title1: string;
  title2: string;
  description: string;
  learnMore: string;
};

const MotionBox = motion(Box);

const HeaderContainerRight: React.FC<HeaderContainerRightProps> = ({
  title1,
  title2,
  description,
}) => {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" justifyContent="center">
      <Flex
        align="center"
        textAlign="center"
        justifyContent="center"
        marginBottom={{ base: '10px', md: '20px' }}
      >
        <Image
          src={starIcon} // Use absolute path from the public directory
          alt="Star Icon"
          height={{ base: '16px', md: '20px' }}
          width={{ base: '16px', md: '20px' }}
          marginLeft={{ base: '5px', md: '10px' }}
          marginBottom="4px"
        />
        <Text
          fontFamily={theme.fonts.family.bold}
          fontSize={{
            base: theme.fonts.size.ultrabig,
          }}
          color={theme.colors.texts.white}
          textAlign="center"
        >
          {title1}
        </Text>
      </Flex>

      {/* Applying background color only to the text width */}
      <Text
        fontFamily={theme.fonts.family.bold}
        fontSize={{
          base: theme.fonts.size.semisemiultra,
        }}
        backgroundColor="#0a3ea8"
        color={theme.colors.texts.white}
        display="inline-block"
        padding="10px 10px"
        borderRadius="5px"
      >
        {title2}
      </Text>
      {/* <Text
        fontFamily={theme.fonts.family.bold}
        fontSize={{
          base: theme.fonts.size.semisemiultra,
        }}
        backgroundColor="#0a3ea8"
        color={theme.colors.texts.white}
        display="inline-block"
        padding="10px 10px"
        borderRadius="5px"
      ></Text> */}

      <Flex justifyContent="center" mt="32px">
        <Button
          onClick={() => window.open('/new-request', '_blank')}
          width={{ base: '100%', md: '70%' }}
          backgroundColor={theme.colors.white}
          border={`1px solid ${theme.colors.primary}`}
          borderRadius="8px"
          h="48px"
          textAlign="center"
          color={theme.colors.texts.black}
          _hover={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.texts.white,
          }}
        >
          جستجوی اساتید
        </Button>
      </Flex>

      <Text
        fontFamily={theme.fonts.family.body}
        fontSize={{ base: theme.fonts.size.medium, md: theme.fonts.size.big }}
        color={theme.colors.texts.white}
        marginBottom={{ base: '10px', md: '20px' }}
      >
        {/* {description} */}
      </Text>

      {/* <Flex
        align="center"
        cursor="pointer"
        onClick={() => navigate('/moredetails')}
      > */}
      {/* Additional content if needed */}
      {/* </Flex> */}
    </Box>
  );
};

export default HeaderContainerRight;
