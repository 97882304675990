import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Stack,
  Image,
  UnorderedList,
  ListItem,
  Divider,
  HStack,
  Center,
} from '@chakra-ui/react';
import theme from '../theme';
import AboutUsJson from '../contents/aboutUsContent.json';
import image1 from '../assets/images/image1.png';
import image2 from '../assets/images/image2.png';
import image3 from '../assets/images/image3.png';
import image4 from '../assets/images/image4.png';
import contactImage from '../assets/images/contactImage.png';

// import image2 from '../assets/images/image2.png';
// import image3 from '../assets/images/image3.png';
// import image4 from '../assets/images/image4.png';

const images = { image1, image2, image3, image4 };

const AboutUs = () => {
  const aboutUsContent = AboutUsJson;

  return (
    <Box
      mx={{ base: '20px', md: '60px', lg: '120px', xl: '180px' }}
      mt={{ base: '24px', md: '48px', lg: '68px' }}
      fontFamily={theme.fonts.family.body}
      fontWeight="400"
      color="#0C0B0B"
    >
      <Heading
        fontFamily={theme.fonts.family.medium}
        fontWeight="500"
        fontSize={{
          base: theme.fonts.size.medium,
          md: `clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`,
        }}
        mb={5}
        textAlign="right"
        bg="#3080E233"
        p={{ base: '20px', md: '30px', lg: '40px' }}
        borderRadius="16px"
      >
        {aboutUsContent.heading}
      </Heading>

      {/* Sections */}
      <VStack spacing={16} align="stretch">
        {aboutUsContent.sections.map((section: any, index: number) => (
          <Stack
            key={index}
            direction={
              index % 2 === 0 ? ['column', 'row'] : ['column', 'row-reverse']
            }
            spacing="53px"
            align="center"
            bg={index % 2 === 0 ? '#F9F9F9' : 'white'}
            p="32px"
            borderRadius="16px"
            shadow="md"
          >
            <Image
              width={['100%', '35%']}
              height="auto"
              src={images[section.imageKey]}
              alt={`Section image ${index + 1}`}
              borderRadius="12px"
            />
            <VStack
              spacing={4}
              align={['center', 'start']}
              textAlign={['center', index % 2 === 0 ? 'start' : 'start']}
              pl={['0px', '20px']}
            >
              <Heading
                fontFamily={theme.fonts.family.bold}
                fontWeight="700"
                fontSize={{
                  base: '20px',
                  md: theme.fonts.size.big,
                }}
                color={theme.colors.secondary}
              >
                {section.title}
              </Heading>

              {section.texts &&
                section.texts.map((text: string, i: number) => (
                  <Text
                    key={i}
                    fontSize={theme.fonts.size.medium}
                    lineHeight="1.8"
                  >
                    {text}
                  </Text>
                ))}
            </VStack>
          </Stack>
        ))}
      </VStack>

      <Text
        my="80px"
        fontFamily={theme.fonts.family.bold}
        fontWeight="500"
        fontSize={{
          base: theme.fonts.size.big,
          md: theme.fonts.size.morebig,
        }}
        maxWidth="1160px"
        mx="auto"
        textAlign="center"
        mb="40px"
      >
        {aboutUsContent.finalText}
      </Text>

      {/* Contact Us Section */}
      <Box
        bg="#d6e6f9"
        p="32px"
        borderRadius="16px 16px 0px 0px"
        maxWidth="1560px"
        mx="auto"
      >
        <Text
          fontFamily={theme.fonts.family.bold}
          fontWeight="700"
          fontSize={{
            base: '20px',
            md: theme.fonts.size.big,
          }}
          mb="20px"
          textAlign="center"
        >
          {aboutUsContent.contactUs.heading}
        </Text>
        <VStack align="center">
          <Stack
            direction={['column', 'row']}
            spacing="53px"
            align="center"
            justify="center"
            py="32px"
          >
            <Image src={contactImage} alt="Contact Us" width={['20%', '20%']} />
            <VStack spacing={4} align="center" direction={['column']}>
              <Stack spacing={4} direction={['column']}>
                {aboutUsContent.contactUs.contactDetails.map(
                  (contact: { type: string; value: string }, index: number) => (
                    <>
                      <Stack
                        gap={'100px'}
                        direction={['row']}
                        justifyContent="space-between"
                      >
                        <Text
                          fontWeight="700"
                          fontSize={theme.fonts.size.big}
                          display="inline"
                        >
                          {contact.type}:
                        </Text>
                        <Text
                          fontSize={theme.fonts.size.big}
                          display="inline"
                          as="span"
                        >
                          {contact.value}
                        </Text>
                      </Stack>
                    </>
                  )
                )}
              </Stack>
            </VStack>
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
};

export default AboutUs;
