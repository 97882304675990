import React, { useEffect, useState } from 'react';
import { Box, Heading, Accordion, Flex, Text, Link } from '@chakra-ui/react';
import theme from '../theme';
import PagesHeader from 'src/components/mobile/PagesHeader';
import CustomAccordion from 'src/components/mobile/Accordion';
import { ReactComponent as CallIcon } from 'src/assets/mobile/icons/CallIcon.svg';
import MoreContentIcon from '../assets/mobile/icons/MoreContentIcon.png';
import faqContent from '../contents/faqContent.json';

const Faq: React.FC = () => {
  // const [faqContent, setFaqContent] = useState([]);
  const sections = faqContent.sections;

  // // Load FAQ content from JSON
  // useEffect(() => {
  //   const loadFaqContent = async () => {
  //     try {
  //       const response = await fetch('/path/to/faqContent.json'); // Adjust path as needed
  //       const data = await response.json();
  //       setFaqContent(data.faqs); // Assuming JSON structure has `faqs`
  //     } catch (error) {
  //       console.error('Error loading FAQ content:', error);
  //     }
  //   };

  //   loadFaqContent();
  // }, []);

  return (
    <Box
      minH="100vh"
      backgroundColor={theme.colors.mobile.pbg}
      paddingTop="64px"
    >
      <PagesHeader text={`سوالات متداول`} showBackButton={true} />
      <Box paddingX="16px" paddingBottom="80px">
        <Accordion allowToggle allowMultiple>
          {sections.map((faq, index) => (
            <CustomAccordion
              key={index}
              id={index}
              title={faq.title} // Assuming `title` is the question key in JSON
              content={faq.content} // Assuming `content` is the answer key in JSON
              icon={MoreContentIcon}
            />
          ))}
        </Accordion>
        <Text fontWeight="500" margin="20px 16px 8px auto">
          مشکلم در فهرست بالا نبود
        </Text>
        <Link href="https://t.me/applytory_admin">
          <Box bg="white" borderRadius="8px" boxShadow="sm" paddingX="12px">
            <Flex
              paddingY="12px"
              justify="space-between"
              align="center"
              borderTop="1px solid #E2E8F0"
              fontFamily={theme.fonts.family.body}
              fontSize={theme.fonts.size.small}
            >
              <Flex align="center">
                <CallIcon />
                <Text ml={2} mr="8px">
                  با ما در تماس باشید
                </Text>
              </Flex>
              <Text fontWeight="700" color="#3080E2">
                پشتیبانی تلگرام
              </Text>
            </Flex>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Faq;
