// components/SecAuthButtonGroup.js
import React, { useContext } from 'react';
import { Box, Button, Link as ChakraLink } from '@chakra-ui/react';
import theme from 'src/theme';
import AuthModal from 'src/components/auth/AuthModal';
import UserDropDown from 'src/components/auth/UserDropDown';
import { useModal } from 'src/context/ModalContext';
import { AuthContext } from 'src/context/AuthContext';
import { useNavigate } from 'react-router-dom';

const SecAuthButtonGroup = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext) || {};
  const { modalType, openModal, closeModal } = useModal();

  const handleLoginClick = () => {
    navigate('/login');
    // openModal('login');
  };

  const handleRegisterClick = () => {
    navigate('/login');
    // openModal('register');
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      {isAuthenticated ? (
        <UserDropDown />
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ChakraLink
            py="14px"
            color={theme.colors.texts.secondary}
            fontFamily={theme.fonts.family.body}
            fontSize={theme.fonts.size.small}
            fontWeight="semibold"
            pl="40px"
            _hover={{
              textDecoration: 'none',
              color: theme.colors.texts.textHover,
            }}
            as="button"
            onClick={handleLoginClick}
          >
            ورود
          </ChakraLink>
          <Button
            fontFamily={theme.fonts.family.bold}
            variant="outline"
            color={theme.colors.texts.secondary}
            fontSize={theme.fonts.size.small}
            borderColor={theme.colors.texts.secondary}
            px="16px"
            py="14px"
            borderRadius="none"
            _hover={{
              bg: theme.colors.texts.secondary,
              color: theme.colors.texts.white,
            }}
            onClick={handleRegisterClick}
          >
            ثبت نام
          </Button>
          {/* {(modalType === 'login' || modalType === 'register') && (
            <AuthModal isOpen={modalType !== null} onClose={handleClose} />
          )} */}
        </Box>
      )}
    </>
  );
};

export default SecAuthButtonGroup;
