import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import { verifyPayment } from '../services/paymentService';
import { ReactComponent as SuccessImage } from 'src/assets/mobile/images/SuccessReqImage.svg';
import { ReactComponent as ErrorImage } from 'src/assets/mobile/images/ReportFailedStateImage.svg';
import theme from '../theme';
import { getPlanData } from '../services/priceService';
import Loader from 'src/components/Loader';
import PagesHeader from 'src/components/mobile/PagesHeader';

const PaymentResult: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [plans, setPlans] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);

  const formatJalaliDate = (gregorianDate: string) => {
    const date = new Date(gregorianDate);
    const formatter = new Intl.DateTimeFormat('fa-IR', {
      dateStyle: 'full',
      timeStyle: 'short',
    });
    const formattedDate = formatter.format(date);
    const dateParts = formattedDate.split(' ');
    const day = dateParts[0].replace(',', '');
    const month = dateParts[1].replace(',', '');
    const year = dateParts[2].replace(',', '');

    return `${formattedDate}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authority = urlParams.get('Authority');
    const status = urlParams.get('Status');

    if (authority && status) {
      verifyPayment(authority, status)
        .then(async (response) => {
          if (response.body.success) {
            const planData = await getPlanData();
            setPlans(planData);
            console.log('planData');
            console.log(planData);

            const selectedPlan = planData.find(
              (plan: any) => plan.id === response.body.reportTypeId
            );
            console.log('selectedPlan');
            console.log(selectedPlan);

            setSelectedPlan(selectedPlan);
            setTransactionDetails(response.body);
            setPaymentSuccess(true);
          } else {
            setErrorMessage(
              response.body.message || 'Payment verification failed.'
            );
            setPaymentSuccess(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage('An error occurred while verifying the payment.');
          setPaymentSuccess(false);
          setLoading(false);
        });
    } else {
      setErrorMessage('Missing authority or status in the callback URL.');
      setPaymentSuccess(false);
      setLoading(false);
    }
  }, [location.search]);

  return (
    <Box
      minH="100vh"
      backgroundColor="#ffff"
      paddingTop="56px"
      paddingBottom="80px"
    >
      {loading ? (
        <>
          <Loader color1="#56A2FF" color2="#FFF" size={200} />
          <Text
            fontFamily={theme.fonts.family.bold}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.semismall}, 1.5vw, ${theme.fonts.size.big})`}
            mt={2}
          >
            در حال بررسی پرداخت شما...
          </Text>
        </>
      ) : (
        <Box padding="16px" textAlign="center">
          <PagesHeader text={`رسید پرداخت`} showBackButton={true} />

          {paymentSuccess ? (
            <>
              <Flex
                width="100%"
                textAlign="center"
                align="center"
                justifyContent="center"
                marginBottom="24px"
              >
                <SuccessImage />
              </Flex>
              <Text
                mt={2}
                fontFamily={theme.fonts.family.body}
                fontWeight="500"
                fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
                marginBottom="24px"
              >
                خرید شما با موفقیت انجام شد
              </Text>
            </>
          ) : (
            <>
              <Box marginBottom="24px">
                <ErrorImage />
              </Box>
              <Text
                mt={2}
                color="red.500"
                fontFamily={theme.fonts.family.bold}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
              >
                پرداخت شما با خطا انجام شد
              </Text>
            </>
          )}
          <Box
            backgroundColor={theme.colors.mobile.secondary}
            fontFamily={theme.fonts.family.body}
            fontWeight="500"
            fontSize={theme.fonts.size.small}
            color={theme.colors.secondary}
            paddingX="16px"
            borderRadius="8px"
            textAlign="start"
            padding="12px 16px"
            marginTop="24px"
            marginBottom="16px"
          >
            <Text fontWeight="700" marginBottom="16px" marginTop="6px">
              مشخصات تراکنش
            </Text>

            <Flex justify="space-between" marginBottom="14px">
              <Text>تاریخ:</Text>
              <Text>
                {transactionDetails
                  ? formatJalaliDate(transactionDetails?.transactionDate)
                  : ''}
              </Text>
            </Flex>
            <Flex justify="space-between" marginBottom="14px">
              <Text>شماره پیگیری:</Text>
              <Text dir="ltr">{transactionDetails?.straceNumber}</Text>
            </Flex>
            <Flex justify="space-between" marginBottom="14px">
              <Text>شماره کارت:</Text>
              <Text dir="ltr">{transactionDetails?.maskedCardNumber}</Text>
            </Flex>
            <Flex justify="space-between" marginBottom="14px">
              <Text>مبلغ:</Text>
              <Text>
                {selectedPlan?.price}
                <Text as="span">ریال</Text>
              </Text>
            </Flex>
            <Flex justify="space-between" marginBottom="6px">
              <Text>نوع اشتراک:</Text>
              <Text>
                {selectedPlan?.price === 0 ? 'اشتراک رایگان' : 'اشتراک بتا'}
              </Text>
            </Flex>
          </Box>

          <Flex
            position="fixed"
            bottom="0"
            // left="0"
            width="100%"
            backgroundColor="#FFFFFF"
            padding="16px"
            maxWidth="600px"
            transform="translateX(-50%)"
            left="50%"
          >
            <Button
              onClick={() => navigate('/dashboard')}
              width="100%"
              backgroundColor={'#EBEBF0'}
              borderRadius="8px"
              h="48px"
              color={theme.colors.secondary}
            >
              بازگشت به صفحه اصلی
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default PaymentResult;
